export default [
    '/', 
    '/membership', 
    "/MyProfile", "/myprofile", 
    "/dashboard", 
    "/settings", 
    "/change_password", 
    '/about-us', 
    '/cv-profile', 
    '/pricing', 
    '/dashboard/cv', 
    "/dashboard/edit", 
    "/dashboard/profiles", 
    "/dashboard/edit-career-objective", 
    "/dashboard/edit-cognetive-skills", 
    "/dashboard/experience-history", 
    "/dashboard/designation-history", 
    "/dashboard/experience-editor",
    '/dashboard/project-history',
    '/dashboard/education-history',
    '/dashboard/education-editor',
    '/dashboard/certificate-history',
    '/dashboard/certificate-editor',
    '/dashboard/contribution-history',
    '/dashboard/contribution-editor',
    '/dashboard/personal-info-editor',
    '/dashboard/hobbies-editor',
    "/dashboard/plans",
    '/dashboard/settings',
    '/dashboard/change_password',
    '/dashboard/portfolio-editor',
    '/dashboard/portfolio-history',
    '/dashboard/referal',
    '/forms',
    '/forms/*',
    '/forms/personal-info',
    '/forms/experience',
    '/forms/education',
    '/forms/certifications',
    '/forms/portfolio',
    '/forms/cognitive-skills',
    '/forms/voluntary-roles',
    '/forms/hobbies-and-languages',
    '/forms/reward-and-recognition',
    '/forms/summary',
    '/forms/social-media',
]