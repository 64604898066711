import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    starterPath: 'type'
}

const formSlice = createSlice({
    name: "formOptions",
    initialState,
    reducers:{
        changeStarterType: (state,action) => {
            state.starterPath = action.payload === 2 ? 'name' : 'type'
        }
    },
    extraReducers: (builder) => {

    }
})

export const getStarterType = (state) => state.formOptions.starterPath;

export const {changeStarterType} = formSlice.actions;
export default formSlice.reducer;