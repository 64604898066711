export default [
    '/membership', 
    "/MyProfile", 
    "/myprofile", 
    "/dashboard", 
    "/settings", 
    "/change_password", 
    "/login", 
    '/signup', 
    '/get-onboard', 
    '/cv-builder', 
    '/OTP-signup', 
    "/dashboard/profiles", 
    '/create-password', 
    '/cv-profile', 
    "/dashboard/cv", 
    "/dashboard/edit", 
    "/dashboard/edit-career-objective", 
    "/dashboard/edit-cognetive-skills", 
    "/dashboard/experience-history", 
    "/dashboard/designation-history", 
    "/dashboard/experience-editor",
    '/dashboard/project-history',
    '/dashboard/education-history',
    '/dashboard/education-editor',
    '/dashboard/certificate-history',
    '/dashboard/certificate-editor',
    '/dashboard/contribution-history',
    '/dashboard/contribution-editor',
    '/dashboard/personal-info-editor',
    '/dashboard/hobbies-editor',
    "/dashboard/plans",
    '/dashboard/settings',
    '/dashboard/change_password',
    '/dashboard/portfolio-editor',
    '/dashboard/portfolio-history',
    '/dashboard/referal',
    '/starter-form',
    '/forms',
    '/forms/*',
    '/forms/personal-info',
    '/forms/experience',
    '/forms/education',
    '/forms/certifications',
    '/forms/portfolio',
    '/forms/cognitive-skills',
    '/forms/voluntary-roles',
    '/forms/hobbies-and-languages',
    '/forms/reward-and-recognition',
    '/forms/summary',
    '/forms/social-media',
]