import React from 'react'
import PlainInput from '../../Util Components/Inputs/PlainInput/PlainInput'
import './AuthPages.css'
import loginImg from '../../Assets/login-img.svg'

export default function CreateAccount() {
  return (
    <div className="auth-page-wrapper">
      <form action="" className="auth-form">
        <h2 className="form-title">Create Account</h2>
        <div className="grid-1-1">
          <PlainInput label="First Name"/>
          <PlainInput label="Last Name"/>
        </div>
        <PlainInput type='email' label="Email" />
        <div className="grid-1-1">
          <PlainInput type='password' label="Password"/>
          <PlainInput type='password' label="Confirm Password"/>
        </div>
        <p className="signup-caution">Use a unique combination of upper- and lowercase letters, numbers, and symbols</p>
        <button className="btn-auth btn-primary">Register</button>
        <p className="auth-divider-text">Already have a Talentplace account?</p>
        <button className="btn-auth btn-auth-another btn-primary">Login</button>
      </form>
      <img src={loginImg} className='auth-right-img' alt="" />
    </div>
  )
}
