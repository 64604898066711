import React from 'react'
import { NavLink, Outlet } from 'react-router-dom'
import formNavList from '../../JSON DB/formNavList'
import logo from '../../Assets/sidebarLogo.png'
import './FormLayout.scss'
import { IoIosArrowBack } from 'react-icons/io'
import { BsCloudCheckFill, BsFillCloudSlashFill } from 'react-icons/bs'

export default function FormLayout() {
    return (
        <div className="form-layout">
            <div className="form-navigation-wrapper">
                <img src={logo} alt="" className="form-nav-logo" />
                <div className="form-nav-btns-wrapper">
                    <button className="btn-back-dashboard">
                        <IoIosArrowBack className='back-icon' />
                        <span>Dasbhoard</span>
                    </button>
                    {
                        formNavList.map((item, index) => (
                            <NavLink className={({isActive}) => isActive ? "form-nav-btn form-nav-btn--active" :"form-nav-btn"} to={'/forms' + item.link} key={index}>
                                <div className="form-nav-icon-wrapper">
                                    <img src={item.icon} alt="" className="form-nav-icon" />
                                </div>
                                <span className="nav-name">{item.name}</span>
                            </NavLink>
                        ))
                    }
                </div>
            </div>
            
            <header className="form-header">
                <div className="saved-wrapper">
                    {/* <BsCloudCheckFill className='save-icon'/> */}
                    <BsFillCloudSlashFill className='save-icon'/>
                    <span>Not Saved</span>
                </div>
            </header>
                    
            <div className="form-layout--container">
                <Outlet />
            </div>
        </div>
    )
}
