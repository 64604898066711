import personal from '../Assets/edit icons/personal.png'
import experience from '../Assets/edit icons/experience.png'
import education from '../Assets/edit icons/education.png'
import certification from '../Assets/edit icons/certification.png'
import portfolio from '../Assets/edit icons/portfolio.png'
import cognitive from '../Assets/edit icons/cognitive.png'
import voluntary from '../Assets/edit icons/voluntary.png'
import hobbies from '../Assets/edit icons/hobbies.png'
import summary from '../Assets/edit icons/career objective.png'

export default [
    {
        name: "Personal Info",
        link: '/personal-info',
        icon: personal
    },
    {
        name: "Experience",
        link: '/experience',
        icon: experience
    },
    {
        name: "Education",
        link: '/education',
        icon: education
    },
    {
        name: "Certifications",
        link: '/certifications',
        icon: certification
    },
    {
        name: "Portfolio",
        link: '/portfolio',
        icon: portfolio
    },
    {
        name: "Cognitive Skills",
        link: '/cognitive-skills',
        icon: cognitive
    },
    {
        name: "Voluntary Roles",
        link: '/voluntary-roles',
        icon: voluntary
    },
    {
        name: "Hobbies & Languages",
        link: '/hobbies-and-languages',
        icon: hobbies
    },
    {
        name: "Reward & Recognition",
        link: '/reward-and-recognition',
        icon: summary
    },
    {
        name: "Summary",
        link: '/summary',
        icon: summary
    },
    {
        name: "Social Media Links",
        link: '/social-media',
        icon: personal
    }
]


