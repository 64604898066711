import React from 'react'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { getCountryCodeList, selectCountryCodes } from '../../../redux/Features/MasterSlice'
import DateInput from '../../../Util Components/Inputs/DateInput/DateInput'
import LocationInput from '../../../Util Components/Inputs/LocationInput/LocationInput'
import PlainInput from '../../../Util Components/Inputs/PlainInput/PlainInput'
import SelectInput from '../../../Util Components/Inputs/SelectInput/SelectInput'
import SuggestionInput from '../../../Util Components/Inputs/SuggestionInput/SuggestionInput'
import './PersonalInfoForm.scss'

export default function PersonalInfoForm() {
    const dispatch = useDispatch();
    const countryCodeList = useSelector(selectCountryCodes);
    console.log(countryCodeList);

    useEffect(() => {
        if(countryCodeList.length === 0) dispatch(getCountryCodeList());
    },[])
    return (
        <form className="main-form-wrapper">
            <h2 className="form-title">Personal Information</h2>
            <div className="grid-1-1-1">
                <PlainInput name="first-name" label="First Name" placeholder="John" />
                <PlainInput name="last-name" label="Last Name" placeholder="Adam" />
                <SelectInput name="" label="Gender" options={["Male", "Female", "Other"]} />
            </div>
            <PlainInput name="headline" label="Headline" />
            <div className="grid-1-1-1">
                <SelectInput name="" label="Currency" options={["USD", "INR", "AED"]} />
                <PlainInput name="" type='date' label="Date of Birth" placeholder="Month Year" />
                <DateInput name="" label="Experience Start Date" />
            </div>
            <PlainInput name="last-name" label="Email" placeholder="johnadam55@gmail.com" />
            <div className="grid-1-1">
                <div className="form-mobile-wrapper">
                    <SuggestionInput label="Mobile" name_field="country_code" name="country_code" suggestions={countryCodeList} />
                    <PlainInput label=" " placeholder="XXX XXXX XX89" />
                </div>
                <LocationInput label="Location" />
            </div>
            <div className="form-video-link-wrapper">
                <PlainInput name="" label="Video Link" placeholder="https://www.myprofile.ai" />
                <button className="btn btn-add" >Add Link</button>
            </div>
            <button className="btn-form-submit">Save</button>
        </form>
    )
}
