import React from 'react'
import PlainInput from '../../Util Components/Inputs/PlainInput/PlainInput'
import './AuthPages.css'
import loginImg from '../../Assets/login-img.svg'
import { Link } from 'react-router-dom'

export default function Signin() {
  return (
    <div className="auth-page-wrapper">
      <form action="" className="auth-form">
        <h2 className="form-title">Login</h2>
        <PlainInput type='email' label="Email" />
        <PlainInput type='password' label="Password"/>
        <Link to='' className="forget">Forget Password?</Link>
        <button className="btn-auth btn-primary">Login</button>
        <p className="auth-divider-text">Don't have a Talentplace account?</p>
        <button className="btn-auth btn-auth-another btn-primary">Register</button>
      </form>
      <img src={loginImg} className='auth-right-img' alt="" />
    </div>
  )
}
