import React from 'react'
import './FormStarter.scss'
import img from '../../Assets/edit icons/education.png'
import { useSelector } from 'react-redux'
import { changeStarterType, getStarterType } from '../../redux/Features/FormSlice'
import { useDispatch } from 'react-redux'

export default function FormStarter() {
    const dispatch = useDispatch();
    const starterType = useSelector(getStarterType);
    console.log(starterType);


    return (
        <div className="form-starter-page">
            <ul className="form-starter-tabs-container">
                <li className={"form-starter-tab "+(starterType === "type" ? "form-starter-tab--active": "")} onClick={() => dispatch(changeStarterType(1))}>Type</li>
                <li className={"form-starter-tab "+(starterType === "name" ? "form-starter-tab--active": "")} onClick={() => dispatch(changeStarterType(2))}>Name</li>
            </ul>
            {
                starterType === "type" ?
                    <ul className="starter-type-container">
                        <li className='starter-type'>
                            <img src={img} className="starter-type-img" alt="" />
                            <span className="starter-type-name">Job</span>
                        </li>
                        <li className='starter-type'>
                            <img src={img} className="starter-type-img" alt="" />
                            <span className="starter-type-name">Job</span>
                        </li>
                        <li className='starter-type'>
                            <img src={img} className="starter-type-img" alt="" />
                            <span className="starter-type-name">Job</span>
                        </li>
                        <li className='starter-type'>
                            <img src={img} className="starter-type-img" alt="" />
                            <span className="starter-type-name">Job</span>
                        </li>
                        <li className='starter-type'>
                            <img src={img} className="starter-type-img" alt="" />
                            <span className="starter-type-name">Job</span>
                        </li>
                        <li className='starter-type'>
                            <img src={img} className="starter-type-img" alt="" />
                            <span className="starter-type-name">Job</span>
                        </li>
                        <li className='starter-type'>
                            <img src={img} className="starter-type-img" alt="" />
                            <span className="starter-type-name">Job</span>
                        </li>
                        <li className='starter-type'>
                            <img src={img} className="starter-type-img" alt="" />
                            <span className="starter-type-name">Job</span>
                        </li>
                        <li className='starter-type'>
                            <img src={img} className="starter-type-img" alt="" />
                            <span className="starter-type-name">Job</span>
                        </li>
                        <li className='starter-type'>
                            <img src={img} className="starter-type-img" alt="" />
                            <span className="starter-type-name">Job</span>
                        </li>
                        <li className='starter-type'>
                            <img src={img} className="starter-type-img" alt="" />
                            <span className="starter-type-name">Job</span>
                        </li>
                        <li className='starter-type'>
                            <img src={img} className="starter-type-img" alt="" />
                            <span className="starter-type-name">Skip</span>
                        </li>
                    </ul>
                    : (starterType === "name") ?
                        <form className="starter-name-form">
                            <label className="starter-label">Name your profile</label>
                            <div className="starter-input-wrapper">
                                <input type="text" className="starter-name" />
                                <button className="btn-starter btn-primary">Let's Start</button>
                            </div>
                        </form>
                    :null

            }

        </div>
    )
}
